<template>
  <div class="opus-group" ref="boxRef">
    <!--  tab-->
    <div class="tabs">
      <a-tabs
          :default-active-key="2"
          @tab-click="tabClick"
      >
        <a-tab-pane
            :title="tab.name"
            v-for="tab in data.tabs"
            :key="tab.code"
        >
          <channelLabel
              :active-tab="data.activeTab"
              :label-list="labelList"
              @back-channel-val="backChannelVal"
              v-if="labelList?.length"
          />
          <!--      作品-->
          <div class="opus-content">
            <waterfall :list="opusList" v-if="opusList?.length"/>
            <empty :index="2" v-else class="no-data"/>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script setup>
const boxRef = ref(null)
import channelLabel from './channelLabel.vue'
import waterfall from './waterfall.vue'
import {ChannelApi, FavoriteApi} from "@/apis/api";

let channelApi = new ChannelApi();
let favoriteApi = new FavoriteApi();
const bottomBoxRef = inject('bottomBoxRef')
const data = reactive({
  tabs: [
    {name: '关注', code: 1},
    {name: '收藏', code: 6,},
    {name: '推荐', code: 2,},
    {name: '热门', code: 3,},
    {name: '视频', code: 4,},
    {name: '同城', code: 5,},
  ],
  activeTab: 2,
  currentCity: "", //当前城市
  currentList: [],//作品列表
  totalCount: 0,//当前tab下作品总条数
  labelList: [],//标签列表
  channelVal: '',//标签id
})
// tab切换
const tabClick = (val) => {
  data.activeTab = val
  data.pageIndex = 1
  data.pageCount = 10
  data.currentList = []
  data.labelList = []
  refreshNuxtData('getOpus')
  refreshNuxtData('getLabels')
}

// 获取当前城市
function getCurrentCity() {
  const {$AMap} = useNuxtApp();
  // console.log('地图', $AMap);
  $AMap.plugin("AMap.CitySearch", function () {
    const citySearch = new $AMap.CitySearch();
    citySearch.getLocalCity(function (status, result) {
      if (status === "complete" && result.info === "OK") {
        // console.log('city', result); // 查询成功，result即为当前所在城市信息
        data.currentCity = result.city;
      }
    });
  });
}

// 获取数据
const {data: opusList} = await useAsyncData('getOpus', async () => {
  let res;
  switch (data.activeTab) {
    case 1:
      res = await channelApi.apiAppChannelGetAttentionPost({
        channelValue: data.channelVal ? data.channelVal : "1",
        pageIndex: data.pageIndex,
        pageCount: 10,
        appType: 3,
      }, {headers: useRequestHeaders(["cookie"])});
      break;
    case 2:
      res = await channelApi.apiAppChannelGetDataPost({
        firstType: 3,
        pageIndex: data.pageIndex,
        pageCount: 10,
        appType: 3,
        channelValue: data.channelVal ? data.channelVal : "1",
      }, {headers: useRequestHeaders(["cookie"])});
      break;
    case 3:
      res = await channelApi.apiAppChannelGetDataPost({
        firstType: 4,
        pageIndex: data.pageIndex,
        pageCount: 10,
        appType: 3,
        channelValue: data.channelVal ? data.channelVal : ''
      }, {headers: useRequestHeaders(["cookie"])});
      break;
    case 4:
      res = await channelApi.apiAppChannelGetVideoPost({
        "channelValue": data.channelVal ? data.channelVal : '',
        "firstType": 5,
        "pageIndex": data.pageIndex,
        "pageCount": 10,
        "appType": 3
      }, {headers: useRequestHeaders(["cookie"])});
      break;
    case 5:
      res = await channelApi.apiAppChannelGetSameCityPost({
        channelValue: data.channelVal ? data.channelVal : '1',
        pageIndex: data.pageIndex,
        pageCount: 10,
        city: data.currentCity,
        appType: 3,
      }, {headers: useRequestHeaders(["cookie"])});
      break;
    case 6:
      res = await channelApi.apiAppChannelGetFavoritePost({
        pageIndex: data.pageIndex,
        pageCount: 10,
        channelValue: data.channelVal ? data.channelVal : '1'
      }, {headers: useRequestHeaders(["cookie"])});
      break;
  }
  data.totalCount = res.data.totalCount;
  for (const item of res.data.items) {
    item.realHeight = item.coverFileHigh / (item.coverFileWidth / 363);
  }
  data.currentList = data.currentList.concat(res.data.items);
  return data.currentList;
});
//获取标签页
const {data: labelList} = await useAsyncData('getLabels', async () => {
  let res = {
    data: []
  };
  switch (data.activeTab) {
    case 1:
      res.data = [
        {title: '全部', id: ''},
        {title: '综合', id: '1'},
        {title: '案例', id: '3'},
        {title: '商家', id: '4'},
        {title: '设计师', id: '5'},
        {title: '装修公司', id: '6'},
        {title: '问答', id: '7'},
      ]
      break
    case 2:
      res = await channelApi.apiAppChannelGetChannelByIdPost({searchType: 3},
          {headers: useRequestHeaders(["cookie"])}
      );
      break
    case 3:
      res = await channelApi.apiAppChannelGetChannelByIdPost({searchType: 4},
          {headers: useRequestHeaders(["cookie"])}
      );
      break

    case 4:
      res.data = [
        {title: '直播', id: '10'},
        {title: '视频', id: '8'},
      ]
      break

    case 5:
      res.data = [
        {title: '综合', id: '1'},
        {title: '设计服务', id: '11'},
        {title: '装修服务', id: '12'},
        {title: '好物推荐', id: '2'},
      ]
      break
    case 6:
      let res1 = await favoriteApi.apiAppFavoriteGetOwnFolderPost({headers: useRequestHeaders(["cookie"])})
      res.data = res1?.data.folders
      res.data.unshift({title: '全部', id: ''})
      break
  }
  if (res.data.length) {
    data.labelList = data.labelList.concat(res.data);
  }
  // console.log(data.labelList, '最后的list');
  return data.labelList;
});
// console.log(labelList.value,'labelList');
// 获取二级选项的id值
const backChannelVal = async (id) => {
  data.channelVal = ''
  data.channelVal = id
  data.currentList = []
  opusList.value = []
  await refreshNuxtData('getOpus')
}

function handleScroll() {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;//滚动条高度
  let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
  if (scrollTop + clientHeight >= bottomBoxRef.value.offsetTop) {//大于底部盒子高度触发
    // if (opusList.value.length) {
    //   console.log(data.currentList.length, 'data.currentList.length');
    //   console.log(data.totalCount, 'data.totalCount');
    // }
    // const {data:opusListLen} = await useAsyncData('')
    if (data.currentList.length === data.totalCount) {
      return
    }
    data.pageIndex += 1
    refreshNuxtData('getOpus')
  }
}

const that = getCurrentInstance()?.appContext.config.globalProperties;

async function onLoad() {
  await nextTick(() => {
    if (typeof that.$redrawVueMasonry === 'function') {
      that.$redrawVueMasonry()
    }
  })
}
const resetData = () =>{
  data.pageIndex = 1
  data.pageCount = 10
  data.currentList = []
  data.labelList = []
  refreshNuxtData('getOpus')
  refreshNuxtData('getLabels')
}
provide('resetData',resetData)
onMounted(async () => {
  await getCurrentCity()
  await onLoad()
  window.addEventListener('scroll', handleScroll);
})
// 取消监听 scroll 事件
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="less" scoped>
.opus-group {
  overflow-y: auto;
  //border: 1px solid red;
  .tabs {
    :deep(.arco-tabs-nav-ink) {
      background-color: transparent;
      background-image: url("@/assets/images/lj2.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      width: 52px !important;
      height: 20px;
      bottom: 6px;
      z-index: -1;
    }

    :deep(.arco-tabs-tab) {
      color: rgba(0, 0, 0) !important;
      font-size: 18px;
      font-weight: 400;
      margin: 0;
      margin-right: 52px;
    }

    :deep(.arco-tabs-tab-active) {
      color: rgba(0, 0, 0) !important;
      font-weight: 600;
      font-size: 24px;
    }

    :deep(.arco-tabs-nav-type-line::before) {
      height: 0;
      background-color: transparent;
    }

    :deep(.arco-tabs-content) {
      padding-top: 6px;
    }

  }

  .opus-content {
    //margin-top: 20px;
    .no-data {
      padding: 100px 0;
    }

    .bottomBox {
      height: 50px;
    }
  }
}
</style>